<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-if="$store.state.villageId>0" v-permission="['integralOrderdefine']">
					<el-button @click="allreceipt" color="#00ad79" class="el-white" style="color: white" size="mini">
						确认领取
					</el-button>
				</li>
				<li v-if="$store.state.villageId>0" v-permission="['integralOrderdele']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.name" placeholder="请输入名称">
					</el-input>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.order_sn" placeholder="请输入订单编号">
					</el-input>
				</li>
				<li>
					<el-select clearable v-model="form.status" placeholder="请选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="已兑换" :value="1"></el-option>
						<el-option label="已领取" :value="2"></el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="name" label="村">
					<template #default="scope">
						<span>{{scope.row.shop?scope.row.shop.name:''}}</span>
					</template>
				</el-table-column>
				<el-table-column property="date" label="商品图片">
					<template #default="scope">
						<el-avatar :size="30" :src="scope.row.product.cover">
						</el-avatar>
					</template>
				</el-table-column>
				<el-table-column property="name" label="兑换人">
					<template #default="scope">
						<span>{{scope.row.user?scope.row.user.name:''}}</span>
					</template>
				</el-table-column>
				<el-table-column property="name" label="商品名称">
					<template #default="scope">
						<span>{{scope.row.product?scope.row.product.name:''}}</span>
					</template>
				</el-table-column>
				<el-table-column property="name" label="兑换积分">
					<template #default="scope">
						<span>{{scope.row.product?scope.row.product.integral:''}}</span>
					</template>
				</el-table-column>
				<el-table-column property="address" label="领取地址" />
				<el-table-column property="name" label="状态">
					<template #default="scope">
						<span>{{scope.row.status==1?'已兑换':'已领取'}}</span>
					</template>
				</el-table-column>
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column property="address" label="操作" width="200" v-if="$store.state.villageId>0">
					<template #default="scope">
						<el-button v-if="scope.row.status==1" v-permission="['integralOrderdefine']" size="mini"
							@click="receipt(scope.row.order_sn)">确认领取</el-button>
						<el-button v-permission="['integralOrderdele']" size="mini" @click="dele(scope.row.order_sn)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/integralOrder";
	export default {
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					name: '',
					page: 1,
					limit: 15,
					order_sn: '',
					status: ''
				},
				tableData: [],
				idlist: [],
			}
		},
		created() {
			if(this.$route.query.state) {
				this.form.status = Number(this.$route.query.state)
				this.getList()
			}
			this.getList()
		},
		methods: {
			// 批量确认领取
			allreceipt() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要领取数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'是否确认领取?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.receipt, {
									order_sn: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '领取成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 确认领取
			receipt(order_sn) {
				ElMessageBox.confirm(
						'是否确认领取?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.receipt, {
								order_sn: order_sn
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '确认成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.order_sn)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									order_sn: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(order_sn) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								order_sn: order_sn
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
</style>
