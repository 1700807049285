import request from './request'

const api = {
	// 获取列表
	lists: '/isc/Order/list',
	// 添加
	add: '/isc/Order/create',
	// 删除
	dele: '/isc/Order/delete',
	// 编辑
	edit: '/isc/Order/update',
	// 详情
	info: '/isc/Order/read',
	// 订单确认领取
	receipt: '/isc/Order/receipt',
}
export {
	request,
	api
}
